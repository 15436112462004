import React from 'react';
import styles from './SalesFees.module.css';


const SalesFees = ({ journalEntries, settlementId, dateRange, missingMappings }) => {


    const isMappingMissing = missingMappings.length > 0;
    return (
        <div>
            <div>
                {(isMappingMissing || !journalEntries.length) && (
                    <div>
                        <p>You need to complete other mappings.</p>
                        <h2>Account Mapping</h2>
                        <ul className={styles.mappingsGrid}>
                            {missingMappings.map(mapping => (
                                <li key={mapping.description}>{mapping.description}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {!isMappingMissing && (
                    <>

                        <h2>Settlement: {settlementId} ({dateRange})</h2>
                        {journalEntries.map(entry => (
                            <div key={entry.journalMiliseconds}>
                                <h2>{entry.journalName}</h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Account</th>
                                            <th>Debits</th>
                                            <th>Credits</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entry.entriesList.map(transaction => (
                                            <tr key={transaction.account}>
                                                <td>{transaction.account}</td>
                                                <td>{parseFloat(transaction.debit) !== 0 ? parseFloat(transaction.debit).toFixed(2) : ''}</td>
                                                <td>{parseFloat(transaction.credit) !== 0 ? parseFloat(transaction.credit).toFixed(2) : ''}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

export default SalesFees;
