import { useState, useEffect } from "react";

export const useFetch = (url, method = "GET", body = null, token = null) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      setIsPending(true);
      
      const headers = {
        'Content-Type': 'application/json'
      };

      if (token) headers['Authorization'] = `Bearer ${token}`;
      else
        return null;

      const requestOptions = {
        method,
        headers,
      };

      if (body) requestOptions.body = JSON.stringify(body);

      try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) throw new Error(response.statusText);
        
        const json = await response.json();
        
        setData(json);
        setError(null);
      } catch (error) {
        setError(`Could not Fetch Data: ${error.message}`);
      } finally {
        setIsPending(false);
      }
    };
    
    fetchData();
  }, [url, method, body, token]);
  
  return { data, isPending, error };
};
