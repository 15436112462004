export const constructProcessURL = (files, filterType, startDate, endDate,userId,profile) => {
    const filenames = Array.from(files).map(file => file.name).join(',');

    let url = `/home/ProcessUserFile?filenames=${filenames}`;
    if (filterType === "byDate") {
        url += `&filterDateStart=${startDate}`;
        url += `&filterDateEnd=${endDate}`;
    }
    if(userId)
        url += `&userId=${userId}`;
    if(profile)
        url += `&profile=${profile}`;

    return url;
};

export const calculateTotalWithRefunds = (quantity, refunded, cost) => {
    return ((quantity ?? 0) - (refunded ?? 0)) * (cost ?? 0);
}
export const getGross = (item) => {
    return (item.totalRevenue - calculateTotalWithRefunds(item.quantitySold, item.quantityRefunded, item.cost).toFixed(2)) / item.totalRevenue;
}
export const sortMappingBySKU = (mappingBySKU) => {
    return mappingBySKU.map(item => {
        const sortedData = item.data.sort((a, b) => 
            -1 * (getGross(a) - getGross(b))
        );
        return {
            ...item,
            data: sortedData
        };
    });
};
