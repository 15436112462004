// src/components/AnalyzerSidebar.js
import React from 'react';
import styles from './AnalyzerSidebar.module.css';
import DataRangeFilter from '../components/DataRangeFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faFilter, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const AnalyzerSidebar = ({
    profile,
    onFilesChange,
    onFilter,
    uploaded,
    uploadFiles,
    handleExportToExcel,
    handleExportToPdf,
    operationType,
    setOperationType
}) => {

    const handleFilesChange = (event) => {
        onFilesChange(event);
        if (event.target.files.length > 0) {
            uploadFiles(event.target.files);
        }
    };

    return (
        <div className={styles.sidebar}>
            <h1 className={styles.title}>Data source</h1>
            <div className={styles.uploadSection}>
                <FontAwesomeIcon icon={faUpload} size='2x' />
                <input
                    type="file"
                    multiple
                    onChange={handleFilesChange}
                    className={styles.fileInput_input}
                />
            </div>
            {uploaded && (
                <>
                    <div className={styles.filterSection}>
                        <FontAwesomeIcon icon={faFilter} size='2x' />
                        <DataRangeFilter onFilter={onFilter} />
                    </div>

                    <div className={styles.exportSection}>
                        <button onClick={handleExportToExcel} className={styles.exportButton}>
                            <FontAwesomeIcon icon={faFileExcel} size='2x' />
                            Export to Excel
                        </button>
                        <button onClick={handleExportToPdf} className={styles.exportButton}>
                            <FontAwesomeIcon icon={faFilePdf} size='2x' />
                            Export to PDF
                        </button>
                    </div>

                    <hr />
                    <br/>
                    <h1 className={styles.title}>Operation</h1>
                    <div className={styles.operationTypeSection}>
                        <div 
                            className={`${styles.operationType} ${operationType === 'COGS' && styles.selected}`} 
                            onClick={() => setOperationType('COGS')}>
                            Cost of goods sold
                        </div>
                        <div 
                            className={`${styles.operationType} ${operationType === 'AccountMapping' && styles.selected}`} 
                            onClick={() => setOperationType('AccountMapping')}>
                            Account Mapping
                        </div>
                        <div 
                            className={`${styles.operationType} ${operationType === 'SalesFees' && styles.selected}`} 
                            onClick={() => setOperationType('SalesFees')}>
                            Sales/Fees
                        </div>
                    </div>
                </>
            )}
       
        </div>
    );
};

export default AnalyzerSidebar;
