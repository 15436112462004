
import { EVAL_ALL, EVAL_POSITIVE, EVAL_NEGATIVE } from './calculationUtils';

export const getMappingAccountByMonth = (mappingByAccount = []) => {
    const dates = mappingByAccount.flatMap(item =>
        item.mappedSettlements.map(settlement =>
            Date.parse(`${settlement.item1.substring(0, 7)}-01T00:00:00`)
        )
    );
    return [...new Set(dates)].sort();
}


export const getTotalAccount = (mappingByAccount, month, account, type = "all") => {
    let total = 0;

    if (mappingByAccount) {
        mappingByAccount.forEach((item) => {
            if (item.account !== account) return;

            item.mappedSettlements.forEach((settlement) => {
                const strDate = Date.parse(settlement.item1.substring(0, 7) + "-01T00:00:00");

                if (strDate === month) {
                    if (type === EVAL_ALL
                        || (type === EVAL_POSITIVE && settlement.item2 >= 0)
                        || (type === EVAL_NEGATIVE && settlement.item2 < 0)) {
                        total += settlement.item2 > 0 ? settlement.item2 : -1 * settlement.item2;
                    }
                }
            });
        });
    }
    return total;
}


export const mapAccountTotals = (mappingByAccount, mappingByAccountMonths, accounts, customAccounts) => {
    const mappedTotals = {};
    const mappingByAccountMonthsTotal = {};
    const accountIdToName = {};

    const processAccounts = (accountList, month) => {
        accountList.forEach(account => {
            const credits = getTotalAccount(mappingByAccount, month, account.id, EVAL_POSITIVE);
            const debits = getTotalAccount(mappingByAccount, month, account.id, EVAL_NEGATIVE);
            mappedTotals[month][account.id] = {
                totalCredits: Math.max(credits - debits, 0),
                totalDebits: Math.max(debits - credits, 0)
            };
            mappingByAccountMonthsTotal[month] = (mappingByAccountMonthsTotal[month] || 0) + credits - debits;
            accountIdToName[account.id] = account.name;
        });
    }

    mappingByAccountMonths.forEach(month => {
        mappedTotals[month] = {};
        if (accounts) processAccounts(accounts, month);
        if (customAccounts) processAccounts(customAccounts, month);
    });

    return { mappedTotals, mappingByAccountMonthsTotal, accountIdToName };


}

export function getMonthNameByMilliseconds(monthMilliseconds) {
    const date = new Date(monthMilliseconds);
    return date.toLocaleString('default', { month: 'long' });
}


export const updateJournalEntries = (mappingByAccountMonths, accounts, specialAccounts, mappedTotals, mappingByAccountMonthsTotal, unify) => {

    console.log("Update Journals:", mappingByAccountMonths, accounts, specialAccounts, mappedTotals, mappingByAccountMonthsTotal, unify);
    if (!mappingByAccountMonths || !accounts || (specialAccounts.length === 0) || !mappedTotals)
        return { updatedJournalEntries :  null};

    const journalEntries = [];
    const lastMonth = mappingByAccountMonths.slice(-1)[0];
    const multipleMonths = mappingByAccountMonths.length > 1;

    const accountsDict = {
        "Clearing Account": specialAccounts.find(x => x.id === "Clearing Account").account,
        "Checking Account": specialAccounts.find(x => x.id === "Checking Account").account,
        "Credit Card Account": specialAccounts.find(x => x.id === "Credit Card Account").account
    };

    console.log(accountsDict);
    const processAccount = (month, accountList) =>
        accountList.flatMap(account => {
            const { totalCredits, totalDebits } = mappedTotals[month][account.id];
            if (totalCredits !== 0 || totalDebits !== 0)
                return { account: account.id, debit: totalDebits, credit: totalCredits };
            return [];
        });

    
    mappingByAccountMonths.forEach(month => {
        const journalName = unify ? "" : getMonthNameByMilliseconds(month);
        let entriesList = [...processAccount(month, accounts), ...processAccount(month, specialAccounts)];

        const a = mappingByAccountMonthsTotal[mappingByAccountMonths[0]];
        const b = mappingByAccountMonthsTotal[month];
        const t = a + (multipleMonths ? b : 0);

        if (month === lastMonth) {
            if(multipleMonths)
                entriesList.push({ account: accountsDict["Clearing Account"], debit: a > 0 ? 0 : a * -1, credit: a > 0 ? a : 0 });
            entriesList.push({
                account: t > 0 ? accountsDict["Checking Account"] : accountsDict["Credit Card Account"],
                debit: Math.max(t, 0),
                credit: Math.max(-t, 0)
            });
        } else {
            entriesList.push({ account: accountsDict["Clearing Account"], debit: Math.max(b, 0), credit: Math.max(-b, 0) });
        }
        journalEntries.push({ journalName, journalMiliseconds: month, entriesList });
    });

    return { updatedJournalEntries: journalEntries, accountIdToName: accountsDict };
}
