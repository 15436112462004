import React, { useState, useContext, useEffect } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { useAuthToken } from '../hooks/useAuthToken';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import AnalyzerContext from '../contexts/AnalyzerContext';

const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { claims: { sub } } = useAuthToken();
  const { profile } = useContext(AnalyzerContext);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
        <NavbarBrand tag={Link} to="/">amzync</NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink tag={Link} className="text-dark" to="/">Login</NavLink>
            </NavItem>
            {profile && (
              <>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/analyzer">Analyzer</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/accounts">Accounts</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/profiles">Switch profile</NavLink>
                </NavItem>
                <NavItem>
                  <span className="profile-name ml-2">User: {sub}</span>
                  <span className="profile-name ml-2">Profile: {profile}</span>
                </NavItem>
              </>
            )}
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );
}

export default NavMenu;
