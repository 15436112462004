import React from 'react';
import styles from './AccountMapping.module.css';

const AccountsTable = ({ title, mappings, accountsList, updateMapping, special = "" }) => (
    <table className={styles.table}>
        <tbody>
            <tr><th colSpan="2">{title}</th></tr>
            {mappings.map((mapping, index) => (
                <tr key={index}>
                    <td style={{ width: "50%" }}>{mapping.description}</td>
                    <td>
                        <select className={styles.select} value={mapping.account} onChange={(e) => { updateMapping(mapping.description,e.target.value,special); }}>
                            <option value={null}>{"[-- Select --]"}</option>
                            {accountsList.map((acc, accIndex) =>
                                <option key={accIndex} value={acc.name}> {acc.name} </option>
                            )}
                        </select>
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
);

const AccountMapping = ({ accountsMappings, accounts, specialAccounts, updateMapping }) => {

    return (
        <div className={styles.container}>
            <div className={styles.header}>Account Mapping</div>

            <AccountsTable title="Profit and loss accounts" mappings={accountsMappings || []} accountsList={accounts || []} updateMapping={updateMapping} />
            <AccountsTable title="Balance Sheet Accounts" mappings={specialAccounts} accountsList={accounts || []} updateMapping={updateMapping} special={"true"}/>

        </div >

    );
};

export default AccountMapping;
