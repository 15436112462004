// src/components/CostOfGoodsSoldTable.js

import React, { useState, useEffect } from 'react';
import styles from './CostOfGoodsSold.module.css';
import { useAuthToken } from '../hooks/useAuthToken';
import { fetchData } from '../utils/fetchUtils';
import { calculateTotalWithRefunds, formatDataItem } from '../utils/calculationUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';

const CostOfGoodsSold = ({ data, liftUpdatedData, profile, userId }) => {



    const { token } = useAuthToken();
    const [updatedData, setUpdatedData] = useState(data || []);
    const [updateTotals , setUpdateTotals] = useState(false);
    const handleCostChange = async (index, newCost) => {
        const newData = updatedData.slice();
        newData[index].cost = parseFloat(newCost);
        setUpdatedData(newData);

        const payload = {
            Source: newData[index].sku,
            Destiny: newCost.toString(),
            Special: "false",
            UserId: parseInt(userId),
            Profile: profile
        };
        setUpdateTotals(!updateTotals);

        await fetchData('/home/UpdateMapping', 'POST', payload, token);
    };

    useEffect(() => {
        if (!data || data.length === 0)
            return;

        const calculatedTotals = getCostTotals(data || []);
        setUpdatedData([calculatedTotals, ...(data || [])].sort((a, b) => b.grossProfit - a.grossProfit));
    }, [data,updateTotals]);

    useEffect(() => {
        liftUpdatedData && liftUpdatedData(updatedData);
    }, [updatedData, liftUpdatedData]);

    const getCostTotals = (costInfo) => {
        if (!costInfo || costInfo.length === 0) {
            return;
        }
        const totalProfit = costInfo.map(x => x.totalRevenue + x.totalCost).reduce((a, b) => a + b, 0);
        const cogs = costInfo.map(x => calculateTotalWithRefunds(x.quantitySold, x.quantityRefunded, x.cost)).reduce((a, b) => a + b, 0);
        const totalRevenue = costInfo.map(x => x.totalRevenue).reduce((a, b) => a + b, 0);

        return {
            sku: "Total",
            cost: costInfo.map(x => x.cost).reduce((a, b) => a + b, 0) / Math.max(costInfo.length, 1),
            quantitySold: costInfo.map(x => x.quantitySold).reduce((a, b) => a + b, 0),
            quantityRefunded: costInfo.map(x => x.quantityRefunded).reduce((a, b) => a + b, 0),
            cogs: cogs.toFixed(2),
            totalWithRefunds: cogs.toFixed(2),
            revenue: totalRevenue.toFixed(2),
            expenses: costInfo.map(x => x.totalCost).reduce((a, b) => a + b, 0).toFixed(2),
            profit: totalProfit.toFixed(2),
            grossProfit: (totalRevenue - cogs).toFixed(2),
            grossMargin: (100 * (totalRevenue - cogs) / totalRevenue).toFixed(2) + '%'
        }
    };


    if (!updatedData || updatedData.length === 0) {
        return (
            <div className={styles.placeholder}>
                <FontAwesomeIcon icon={faTable} size='3x' />
                <div>Table Placeholder</div>
            </div>);
    }

    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>SKU</th>
                        <th>Cost Price</th>
                        <th>Units Sold</th>
                        <th>Refunds</th>
                        <th>COGS</th>
                        <th>Revenue</th>
                        <th>Expenses</th>
                        <th>Profit</th>
                        <th>Gross Profit</th>
                        <th>Gross Margin</th>
                    </tr>
                </thead>
                <tbody>
                    {updatedData.map((item, index) => {

                        const totalsRow = item.sku === "Total";
                        const resultItem = totalsRow ? item : formatDataItem(item);
                        
                        return (
                            <tr key={index} className={totalsRow ? styles.totalsRow : ""}>
                                <td>{resultItem.sku}</td>
                                <td>
                                    {
                                        totalsRow ? "" : (<input
                                            type="number"
                                            step="0.01"
                                            className={styles.input}
                                            value={item.cost}
                                            onChange={e => handleCostChange(index, e.target.value)}
                                        />)
                                    }

                                </td>
                                <td>{resultItem.quantitySold}</td>
                                <td>{resultItem.quantityRefunded}</td>
                                <td>{resultItem.cogs}</td>
                                <td>{resultItem.revenue}</td>
                                <td>{resultItem.expenses}</td>
                                <td>{resultItem.profit}</td>
                                <td>{resultItem.grossProfit}</td>
                                <td>{resultItem.grossMargin}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default CostOfGoodsSold;