import { Login } from "./views/Login";
import { Profiles } from "./views/Profiles";
import { Analyzer } from "./views/Analyzer";
import { Accounts } from "./views/Accounts";

const AppRoutes = [
  {
    index: true,
    path: '/',
    element: <Login />
  },
  {
    path: '/accounts',
    element: <Accounts />
  },
  {
    path: '/analyzer',
    element: <Analyzer />
  },
  {
    path: '/profiles',
    element: <Profiles />
  }
];

export default AppRoutes;
