// src/utils/exportUtils.js
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import { formatDataItem } from "./calculationUtils";

export const exportCOGSToPDF = (data, fileName = 'export.pdf') => {
    const doc = new jsPDF({
        orientation: "landscape"
    });
    const tableColumn = ["SKU", "Cost Price", "Units Sold", "Refunds", "COGS", "Revenue", "Expenses", "Profit", "Gross Profit", "Gross Margin"];
    const tableRows = data.map(item => {
        const formattedItem = formatDataItem(item);
        return [
            formattedItem.sku,
            formattedItem.cost,
            formattedItem.quantitySold,
            formattedItem.quantityRefunded,
            formattedItem.cogs,
            formattedItem.revenue,
            formattedItem.expenses,
            formattedItem.profit,
            formattedItem.grossProfit,
            formattedItem.grossMargin
        ];
    });

    doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        didDrawCell: function (data) {
            if (data.section === 'body') {
                doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
            }
        },
        columnStyles: tableColumn.reduce((acc, col, index) => {
            acc[index] = { cellWidth: 'wrap' };
            return acc;
        }, {}),
    });

    doc.save(fileName);
};

export const exportCOGSToExcel = (data, fileName = 'export.xlsx') => {
    const formattedData = data.map(item => item.sku === "Total"?item: formatDataItem(item));

    const dataForExcel = formattedData.map(item => ({
        "SKU": item.sku,
        "Cost Price": item.cost,
        "Units Sold": item.quantitySold,
        "Refunds": item.quantityRefunded,
        "COGS": item.cogs,
        "Revenue": item.revenue,
        "Expenses": item.expenses,
        "Profit": item.profit,
        "Gross Profit": item.grossProfit,
        "Gross Margin": item.grossMargin,
    }));

    const ws = XLSX.utils.json_to_sheet(dataForExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "COGS");
    XLSX.writeFile(wb, fileName);
};


export const exportSettlementToPDF = (journalEntries, settlementId, dateRange, fileName = 'settlement_export.pdf') => {
    const doc = new jsPDF({
        orientation: "landscape"
    });
    journalEntries.forEach(entry => {
        doc.addPage();
        doc.text(`Settlement: ${settlementId} (${dateRange})`, 14, 16);
        doc.text(entry.journalName, 14, 25);
        const tableColumn = ["Account", "Debits", "Credits"];
        const tableRows = entry.entriesList.map(transaction => [
            transaction.account,
            transaction.debit ? parseFloat(transaction.debit).toFixed(2) : "",
            transaction.credit ? parseFloat(transaction.credit).toFixed(2) : ""
        ]);

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
            theme: 'grid',
            didDrawCell: data => {
                if (data.section === 'body' && data.column.index === 0) {
                    doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height);
                }
            },
            columnStyles: {
                0: { cellWidth: 'auto' },
                1: { cellWidth: 'auto' },
                2: { cellWidth: 'auto' }
            },
        });
    });

    doc.deletePage(1); // Remove the initial blank page
    doc.save(fileName);
};

export const exportSettlementToExcel = (journalEntries, settlementId, dateRange, fileName = 'settlement_export.xlsx') => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([]);

    // Start the first table at row 1 (Excel rows are 1-indexed)
    let currentRow = 1;

    journalEntries.forEach((entry, index) => {
        // Add a title for each settlement
        XLSX.utils.sheet_add_aoa(ws, [
            [`Settlement: ${settlementId} (${dateRange})`],
            [entry.journalName]
        ], { origin: `A${currentRow}` });

        // Increment the row to start the table
        currentRow += 3;

        // Create the table headers
        const tableHeaders = [["Account", "Debits", "Credits"]];

        // Add the headers to the sheet
        XLSX.utils.sheet_add_aoa(ws, tableHeaders, { origin: `A${currentRow}` });

        // Increment the row to start the table content
        currentRow += 1;

        // Add the table content
        entry.entriesList.forEach(transaction => {
            XLSX.utils.sheet_add_aoa(ws, [
                [
                    transaction.account,
                    parseFloat(transaction.debit).toFixed(2),
                    parseFloat(transaction.credit).toFixed(2)
                ]
            ], { origin: `A${currentRow}` });
            currentRow += 1; // Move to the next row after adding each transaction
        });

        // Add an empty row after each table for spacing
        currentRow += 1;
    });

    // Set the worksheet name as 'Settlements'
    XLSX.utils.book_append_sheet(wb, ws, "Settlements");

    // Write the workbook to a file
    XLSX.writeFile(wb, fileName);
};
