// src/utils/calculationUtils.js

export const EVAL_ALL = "all";
export const EVAL_POSITIVE = "positive";
export const EVAL_NEGATIVE = "negative";

export const calculateTotalWithRefunds = (quantity, refunded, cost) => {
    return ((quantity ?? 0) - (refunded ?? 0)) * (cost ?? 0);
};

export const formatDataItem = (item) => {
    const expenses = item.totalCost;
    const revenue = item.totalRevenue;
    const profit = revenue + expenses;
    const cogs = item.cost * (item.quantitySold - item.quantityRefunded);
    const grossProfit = revenue - cogs;
    const grossMargin = (100 * grossProfit / revenue).toFixed(2) + '%';

    return {
        sku: item.sku,
        cost: item.cost.toFixed(2),
        quantitySold: item.quantitySold,
        quantityRefunded: item.quantityRefunded,
        cogs: cogs.toFixed(2),
        revenue: revenue.toFixed(2),
        expenses: expenses.toFixed(2),
        profit: profit.toFixed(2),
        grossProfit: grossProfit.toFixed(2),
        grossMargin: grossMargin
    };
};
