import React, { useState } from 'react';
import styles from './DataRangeFilter.module.css';

const DataRangeFilter = ({ onFilter }) => {
    const [filterType, setFilterType] = useState('bySettlement');
    const [startDate, setStartDate] = useState('1900-01-01');
    const [endDate, setEndDate] = useState('2100-01-01');

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    const handleApplyFilter = () => {
        onFilter(filterType, startDate, endDate);
    };

    return (
        <div className={styles.filterContainer}>
            <div className={styles.filterSelection}>
                <label className={styles.filterLabel}>Filter:</label>
                <div className={styles.customSelect}>
                    <select value={filterType} onChange={handleFilterChange} className={styles.select}>
                        <option value="bySettlement">By Settlement</option>
                        <option value="byDate">By Date</option>
                    </select>
                </div>
            </div>
            {filterType === 'byDate' && (
                <div className={styles.dateRange}>
                    <span className={styles.dateLabel}>Date range:</span>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className={styles.dateInput} />
                    <span className={styles.dash}> - </span>
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className={styles.dateInput} />
                </div>
            )}
            <div className={styles.applyButton}>
                <button className={styles.button} onClick={handleApplyFilter}>Apply Filter</button>
            </div>
        </div>
    );
};

export default DataRangeFilter;
