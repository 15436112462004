import React, { useState, useContext, useEffect, useCallback } from 'react';
import styles from './Accounts.module.css';
import AnalyzerContext from '../contexts/AnalyzerContext'; 
import { fetchData } from '../utils/fetchUtils';
import { useAuthToken } from '../hooks/useAuthToken';

export const Accounts = () => {
    const { profile } = useContext(AnalyzerContext);
    const { token, claims: { UserId } } = useAuthToken();
    const [accounts, setAccounts] = useState([]);
    const [accountName, setAccountName] = useState('');

    const fetchAccounts = useCallback(async () => {
        setAccounts(await fetchData(`account/GetCustomAccounts?UserId=${UserId}&Profile=${profile}`, 'GET', null, token));
    }, [UserId, profile, token]);

    useEffect(() => {
        fetchAccounts();
    }, [fetchAccounts]); 

    const handleAdd = async () => {
        const requestBody = {
            UserId: parseInt(UserId),
            Profile: profile,
            LstCustomAccount: [{ Id: accountName, Name: accountName }]
        };
    
        if ((await fetchData('account/UpdateCustomAccounts', 'POST', requestBody, token)) === 'Ok') {
            fetchAccounts();
        }
    }
    
    
    
    const handleDelete = async (name) => {
        if ((await fetchData(`account/DeleteCustomAccount?Name=${name}&UserId=${UserId}&Profile=${profile}`, 'DELETE', null, token)) === 'Deleted Ok') 
        fetchAccounts();
    }

    return (
        <div className={styles.container}>
            <div className={styles.addAccount}>
                <input 
                    value={accountName} 
                    onChange={(e) => setAccountName(e.target.value)} 
                    placeholder="Add account" 
                />
                <button onClick={handleAdd}>Add Account</button>
            </div>
    
            <table>
                <thead>
                    <tr>
                        <th>Account Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {accounts.map(x => (
                        <tr key={x.id}>
                            <td>{x.name}</td>
                            <td>
                                <button onClick={() => handleDelete(x.name)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
    
}

export default Accounts;
