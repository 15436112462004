const fetchData = async (url, method, body = null, token = null) => {
    
    try {
        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: body ? JSON.stringify(body) : null
        });
        const responseData = await response.json();
        
        if (!response.ok) throw new Error(responseData.message || response.statusText);
        return responseData;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};

export { fetchData };
