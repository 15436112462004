import React, { useState } from 'react';
import styles from './Login.module.css';
import { useNavigate } from 'react-router-dom';


export const Login = () => {
    const [credentials, setCredentials] = useState({ username: 'brlch', password: 'helada2011' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { username, password } = credentials;
    const navigate = useNavigate();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({ ...prev, [name]: value }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await fetch('auth/login', {
                method: 'Post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(credentials)
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('jwt', data.token); 
                navigate('/profiles');
            } else {
                const data = await response.json();
                setError(data.error || 'Login failed.');
            }
        } catch (err) {
            console.log(err);
            setError('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className={styles.container}>
            {error && <div className={styles.error}>{error}</div>}
            <form onSubmit={handleSubmit}>
                <input name="username" value={username} onChange={handleChange} placeholder="Username" />
                <input name="password" type="password" value={password} onChange={handleChange} placeholder="Password" />
                <button type="submit" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                </button>
            </form>
        </div>
    );
}

export default Login;
