import { useState } from 'react';
import { decodeJwt } from '../utils/authUtils';

export const useUpload = () => {
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState(false);
    const token = localStorage.getItem('jwt');
    const tokenData = decodeJwt(token);
    const userId = tokenData.UserId;

    const handleUpload = async (files) => {
        setUploading(true);
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        formData.append('UserId', userId);
        
        try {
            const response = await fetch('home/UploadFile', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });
            
            if (!response.ok) {
                console.error("Error uploading files");
            }
            setFiles(files);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setUploading(false);
        }
    };

    return [uploading, files, handleUpload];
};
