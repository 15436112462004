import React, { useState, useEffect, useMemo, useContext } from 'react';
import AnalyzerContext from '../contexts/AnalyzerContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './Profiles.module.css';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../hooks/useFetch';
import { useAuthToken } from '../hooks/useAuthToken';

export const Profiles = () => {
    const [profiles, setProfiles] = useState([]);
    const { setProfile } = useContext(AnalyzerContext);
    const [newProfileName, setNewProfileName] = useState('');
    const [showCreateProfileForm, setShowCreateProfileForm] = useState(false);
    const [showRestoreProfiles, setShowRestoreProfiles] = useState(false);
    const { token, claims } = useAuthToken();
    const bodyPayload = useMemo(() => ({ UserId: claims.UserId }), [claims.UserId]);
    const { data, /* isPending,*/ error } = useFetch('home/GetProfiles', 'POST', bodyPayload, token);

    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setProfiles(data.filter(p => p && p.trim() !== ''));
        }
        if (error) {
            console.error('Failed to fetch profiles:', error);
        }
    }, [data, error]);

    const backgroundColors = ["#1abc9c", "#3498db", "#2ecc71", "#9b59b6", "#34495e", "#f1c40f", "#e67e22", "#e74c3c", "#ecf0f1"];
    const foregroundColors = ["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#000000", "#ffffff", "#ffffff", "#000000"];

    const getColor = (name, list) => {
        const hash = [...name].reduce((acc, char) =>
            char.charCodeAt(0) + ((acc << 5) - acc), 0);
        return list[Math.abs(hash) % list.length];
    }
    const getBackgroundColorFromName = (name) => {
        return getColor(name, backgroundColors);
    }
    const getForegroundColorFromName = (name) => {
        return getColor(name, foregroundColors);
    }

    const handleCreateProfile = () => {
        setProfile(newProfileName);
        navigate('/analyzer');
    }

    /* const handleRestoreProfile = (profileName) => {
        // Logic for restoring a deleted profile
        // Call an API endpoint to restore the profile
        // Then refresh profiles list
        return "";
    }*/

    const handleEnterProfile = (profile) => {
        setProfile(profile);
        navigate('/analyzer');
    }

    const handleDeleteProfile = (profile) => {
        const confirmation = window.confirm('Are you sure you want to delete this profile?');
        if (confirmation) {
            // Logic for when a profile is deleted.
        }
    }


    return (
        <div className={styles.container}>
            <h2>Select a profile:</h2>
            <ul className={styles.profileList}>
                {profiles.map(profile => (
                    <li key={profile} className={styles.profileSquare} onClick={() => handleEnterProfile(profile)} style={{ backgroundColor: getBackgroundColorFromName(profile), color: getForegroundColorFromName(profile) }}>
                        <FontAwesomeIcon icon={faUserCircle} size='4x' className={styles.profileIcon} />
                        <span className={styles.profileLabel}>
                            {profile}
                        </span>
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            className={styles.trashBinIcon}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteProfile(profile);
                            }}
                        />
                    </li>
                ))}
            </ul>


            {showCreateProfileForm ? (
                <div className={styles.otherSections}>
                    <h3>Create new profile:</h3>
                    <input
                        className={styles.inputField}
                        value={newProfileName}
                        onChange={e => setNewProfileName(e.target.value)}
                        placeholder="Profile name"
                    />
                    <button className={styles.button} onClick={handleCreateProfile}>Create Profile</button>
                    <button className={styles.button} onClick={() => setShowCreateProfileForm(false)}>Cancel</button>
                </div>
            ) : (
                <button className={styles.button} onClick={() => setShowCreateProfileForm(true)}>+ Create New Profile</button>
            )}

            {showRestoreProfiles ? (
                <div className={styles.otherSections}>
                    <h3>Restore deleted profiles:</h3>
                    <ul className={styles.profileList}>
                        {/* ... [deletedProfiles map] */}
                    </ul>
                    <button className={styles.button} onClick={() => setShowRestoreProfiles(false)}>Cancel</button>
                </div>
            ) : (
                <button className={styles.button} onClick={() => setShowRestoreProfiles(true)}>Restore Deleted Profiles</button>
            )}
        </div>
    );
}
