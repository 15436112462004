import React, { useState } from 'react';
import AnalyzerContext from './AnalyzerContext';

const AnalyzerProvider = ({ children }) => {
    const [profile, setProfile] = useState(null);

    return (
        <AnalyzerContext.Provider value={{ profile, setProfile }}>
            {children}
        </AnalyzerContext.Provider>
    );
};

export default AnalyzerProvider;
