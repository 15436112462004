import { useState, useEffect } from 'react';
import { decodeJwt } from '../utils/authUtils';

export const useAuthToken = () => {
  const [token, setToken] = useState(null);
  const [claims, setClaims] = useState({});

  useEffect(() => {
    const storedToken = localStorage.getItem('jwt');
    if (storedToken) {
      setToken(storedToken);
      const decodedClaims = decodeJwt(storedToken);
      setClaims(decodedClaims);
    }
  }, []);

  return { token, claims };
};
