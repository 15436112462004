// src/pages/Analyzer.js

import React, { useEffect, useState, useCallback, useContext } from 'react';
import AnalyzerContext from '../contexts/AnalyzerContext';
import styles from './Analyzer.module.css';
import { constructProcessURL, sortMappingBySKU } from '../utils/dataProcessing';
import { useAuthToken } from '../hooks/useAuthToken';
import AnalyzerSidebar from '../components/AnalyzerSidebar';
import { useUpload } from '../hooks/useUpload';
import { fetchData } from '../utils/fetchUtils';
import { exportCOGSToPDF, exportCOGSToExcel, exportSettlementToExcel, exportSettlementToPDF } from '../utils/exportUtils';
import { updateJournalEntries, mapAccountTotals, getMappingAccountByMonth } from '../utils/accountUtils';
import CostOfGoodsSold from '../components/CostOfGoodsSold';
import AccountMapping from '../components/AccountMapping';
import SalesFees from '../components/SalesFees';

export const Analyzer = () => {
  const [uploading, files, handleUpload] = useUpload();
  const [filterData, setFilterData] = useState({
    filterType: null,
    startDate: null,
    endDate: null,
  });
  const [operationType, setOperationType] = useState('COGS');
  const [sourceData, setSourceData] = useState();
  const [updatedData, setUpdatedData] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [unify, setUnify] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [settlementId, setSettlementId] = useState(null);
  const [journalEntries, setJournalEntries] = useState([]);
  const [accountsMappings, setAccountsMappings] = useState([]);
  const [specialAccountMappings, setSpecialAccountMappings] = useState([]);
  const [missingMappings, setmissingMappings] = useState([0]);

  const { token, claims } = useAuthToken();
  const { profile } = useContext(AnalyzerContext);

  useEffect(() => {
    (async () => {
      if (!token || !claims.UserId)
        return;
      setAccounts(await fetchData(`account/GetCustomAccounts?UserId=${claims.UserId}&Profile=${profile}`, 'GET', null, token));
      setSpecialAccountMappings(await fetchData(`home/GetSpecialMappingAccounts?UserId=${claims.UserId}&Profile=${profile}`, 'GET', null, token));
    })();
  }, [token, claims, profile]);

  useEffect(() => {
    if (missingMappings.length > 0)
      return;
    console.log(accountsMappings,accounts,specialAccountMappings);
    const mappingByAccountMonths = getMappingAccountByMonth(accountsMappings);
    const { mappedTotals, mappingByAccountMonthsTotal } = mapAccountTotals(accountsMappings, mappingByAccountMonths, accounts, specialAccountMappings);
    const { updatedJournalEntries } = updateJournalEntries(mappingByAccountMonths, accounts, specialAccountMappings ?? [], mappedTotals, mappingByAccountMonthsTotal, unify);
    setJournalEntries(updatedJournalEntries);
  }, [accountsMappings, accounts, specialAccountMappings, unify, missingMappings]);

  useEffect(() => {
    setmissingMappings(accountsMappings.filter(mapping => !mapping.account));
  }, [accountsMappings]);

  const handleFilesChange = (event) => {
    // Handle files change logic
  };

  const handleProcess = useCallback(async () => {
    const { filterType, startDate, endDate } = filterData;
    let userId = claims.UserId;
    const url = constructProcessURL(files, filterType, startDate, endDate, userId, profile);
    const data = await fetchData(url, 'POST', null, token);
    console.log(data);
    setUnify(data.unify);
    setSettlementId(data.settlementDate);
    setDateRange(data.dateRange);
    setAccountsMappings(data.mappingByAccount)
    setSourceData(sortMappingBySKU(data.mappingBySKU)[0].data);

  }, [filterData, claims, files, token, profile]);

  useEffect(() => {
    if (handleProcess && !uploading && files) {
      handleProcess();
    }
  }, [uploading, files, handleProcess]);

  const handleExportToExcel = () => {
    switch (operationType) {
      case "COGS": exportCOGSToExcel(updatedData); break;
      case "SalesFees": exportSettlementToExcel(journalEntries,settlementId,dateRange); break;
      default: return;
    }

  };

  const handleExportToPDF = () => {
    switch (operationType) {
      case "COGS": exportCOGSToPDF(updatedData); break;
      case "SalesFees": exportSettlementToPDF(journalEntries,settlementId,dateRange); break;
      default: return;
    }
  };

  const handleUpdateMapping = async (source, destiny, special) => {
    const requestBody = {
      UserId: parseInt(claims.UserId),
      Profile: profile,
      Source: source,
      Destiny: destiny,
      Special: special
    };

    const response = await fetchData('home/UpdateMapping', 'POST', requestBody, token);
    if (response.result === 'ok') {
      const targetState = special === "true" ? specialAccountMappings : accountsMappings;
      const updateFunc = special === "true" ? setSpecialAccountMappings : setAccountsMappings;

      const updatedState = targetState.map(mapping =>
        mapping.description === source ? { ...mapping, account: destiny } : mapping
      );
      updateFunc(updatedState);
    }
    console.log("FROM", source, "TO", destiny);
  }


  /* const handleSendToQuickbooks = () => {
    // Logic to handle the "Send to Quickbooks" button click
  };*/

  const handleDataFilter = (filterType, startDate, endDate) => {
    setFilterData({
      filterType,
      startDate,
      endDate,
    });
  };

  const mainContentClass = styles.mainContent;

  return (
    <div className={styles.container}>
      <AnalyzerSidebar
        profile={profile}
        onFilesChange={handleFilesChange}
        onFilter={handleDataFilter}
        handleProcess={handleProcess}
        uploaded={files}
        uploadFiles={handleUpload}
        handleExportToExcel={handleExportToExcel}
        handleExportToPdf={handleExportToPDF}
        operationType={operationType}
        setOperationType={setOperationType}
      />
      <div className={mainContentClass}>
        {operationType === 'COGS' && <CostOfGoodsSold data={sourceData} liftUpdatedData={setUpdatedData} profile={profile} userId={claims.UserId} accounts={accounts} />}
        {operationType === 'AccountMapping' && <AccountMapping accountsMappings={accountsMappings} accounts={accounts} updateMapping={handleUpdateMapping} specialAccounts={specialAccountMappings} />}
        {operationType === 'SalesFees' && <SalesFees journalEntries={journalEntries} missingMappings={missingMappings} settlementId={settlementId} dateRange={dateRange} />}
      </div>
    </div>
  );
}

export default Analyzer;
